import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"

export const ProductListPageTemplate = ({ title, formJsFile, formId, formSrc, formClass, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section" style={{padding: "0px", paddingBottom: "20px", marginLeft: "-15px"}}>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                IMMUNITY<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span> Product Finder
              </h2>
              <div style={{textAlign: 'right', backgroundColorx: "#ff0000", position: "relative", top:"-69px", margin:"0px", padding:"0px"}} className="column is-3x ef-button-report-effectiveness">
              <Link className="btn" to="/forms/add-product" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
                Add Product
              </Link>
              </div>
              <div class="ef-search-list" style={{textAlign: 'left', backgroundColorx: "blue", position: "relative", margin:"0px", padding:"0px"}}>
                <PageContent className="content" content={content}/>
              </div>

              <div align="center"><Helmet>
                <script src={withPrefix('{formJsFile}')} type="text/javascript" />
              </Helmet>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ProductListPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  formJsFile: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  formSrc: PropTypes.string.isRequired,
  formClass: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ProductListPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.frontmatter.pageMetadata}>
      <ProductListPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        formJsFile={post.frontmatter.formJsFile}
        formId={post.frontmatter.formId}
        formSrc={post.frontmatter.formSrc}
        formClass={post.frontmatter.formClass}
        content={post.html}
      />
    </Layout>
  )
}

ProductListPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductListPage

export const productListPageQuery = graphql`
  query ProductListPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        title
        formJsFile
        formId
        formSrc
        formClass
      }
    }
  }
`
